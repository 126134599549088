export enum PaymentNameType {
  PIX = 'PIX',
  BOLETO = 'BOLETO',
  BV_FINANCING = 'Banco BV',
  BRADESCO_FINANCING = 'Bradesco',
  ITAU_FINANCING = 'Itaú',
  SICOOB_FINANCING = 'Sicoob',
  BANCO_DO_BRASIL_FINANCING = 'Banco do Brasil',
  OTHER_FINANCING = 'Other Financing',
}

export enum PaymentType {
  PIX = 'PIX',
  BOLETO = 'BOLETO',
  BV_FINANCING = 'BV_FINANCING',
  BRADESCO_FINANCING = 'BRADESCO_FINANCING',
  ITAU_FINANCING = 'ITAU_FINANCING',
  SICOOB_FINANCING = 'SICOOB_FINANCING',
  BANCO_DO_BRASIL_FINANCING = 'BANCO_DO_BRASIL_FINANCING',
  OTHER_FINANCING = 'OTHER_FINANCING',
}

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  BOLETO = 'BOLETO',
  DEPOSIT = 'DEPOSIT',
  TRANSFER = 'TRANSFER',
  FINANCING = 'FINANCING',
  BANK_GUARANTEE = 'BANK_GUARANTEE',
  PIX = 'PIX',
  CASH = 'CASH',
}
